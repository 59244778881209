.logincontainer {
    text-align: center;
}

.logoImage {
    width: 250px;
}

.inputWidth {
    width: 95%;
}


.signIn {
    color: #e31e31;
    text-align: center;
    font-size: 2.4rem;
    padding: 15px 0px 0px 0px;
    font-weight: 700;
}

.signInBut {
    color: hsl(0, 0%, 100%);
    padding: 8px;
    text-align: center;
    border: 1px solid #e31e31;
    background-color: #e31e31;
    font-size: 20px;
    font-weight: 600;
    width: auto;
    margin-top: 20px;
}

.signInBut:hover {
    background-color: #ffffff;
    color: #e31e31;
}


.mobileText {
    text-align: left;
    font-size: 12px;
    font-weight: 600;
}