.mainMenu {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding-top: 50px;
}

.menuHeading h2 {
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 1.2;
}
.menuHeading p {
  font-size: 1.5rem !important;
  line-height: 14px;
}

.mealList {
  text-align: center;
}
.menuDeal {
  background-color: orange;
  border-radius: 7rem;
  height: 13vw;
  width: 13vw;
  box-shadow: 5px 5px 6px rgb(203, 204, 204);
}
.mealsheading {
  padding-top: 10px;
}

.mealsheading h3 {
  font-size: 1.3rem;
  color: rgb(250, 89, 89) !important;
  font-weight: bold;
}

.buildMeal {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 15px;
  width: 100%;
}

.buildMealImage {
  flex: 0 0 30%;
}
.buildMealHead {
  flex: 0 0 70%;
  align-self: end;
}

.buildMealHead h2 {
  font-size: 5rem;
}

.buildMealHead p {
  font-size: 2.5rem;
  color: #444343;
}

.toppingSection {
  border-left: 2px solid #e41e31;
  padding-left: 100px;
  padding-bottom: 4rem;
}

.entreeText {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 0px 22px;
}

.entreeText h2 {
  position: relative;
  margin-top: -15px;
  font-size: 2.5rem;
  padding-bottom: 20px;
}

.entreeText h2::before {
  content: "";
  position: absolute;
  left: -123px;
  top: 15px;
  width: 94px;
  height: 2px;
  background-color: #e41e31;
}

.entreeText h2::after {
  content: "";
  position: absolute;
  left: -30px;
  top: 12px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e41e31;
}

.entreeMealList {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
}

.entreeMealsDetail {
  /* flex: 0 0 20%; */
  float: left;
  margin-top: 20px;
  padding-right: 30px;
  width: 20%;
}

.entreeMealsDetail h4 {
  color: #464646;
  font-size: 20px;
  padding: 10px 0px;
  font-weight: bold;
}

.menuButtonSec {
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 9999999999999999;
}

.CheckOutButton {
  border: none;
  background-color: #198754;
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  margin-right: 15px;
}

.TryOutButton {
  border: none;
  color: #000;
  background-color: #ffc107;

  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;

  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  margin-left: 15px;
}

.sidesWingsMenu {
  padding-top: 60px;
  text-align: center;
  /* width: 90%; */
  margin: 0 auto;
}

.buildMealDropdownsImages {
  flex: 0 0 40%;
  /* text-align: center; */
}

.buildMealDropdownsImages img {
  padding: 10px 30px;
}

.sidesMain {
  border: 1px solid #000;
}

.sidesSection {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 1.5rem !important;
}

.sidesDivInfo {
  display: flex;
  /* text-align: center; */
  flex-wrap: wrap;
  width: 100%;
}
.sidesSectionUl {
  width: 100%;
  padding-left: 5px;
}

.sidesInfo {
  float: left;
  margin-top: 20px;
  padding-right: 30px;
  width: 50%;
}

.sidesDetailsSection {
  display: flex;
}

.sideCounterPrice {
  display: flex;
}

.sideCounterPrice .decreaseButton {
  background-color: rgb(243, 175, 175);
  height: 32px;
  text-align: center;
  border-radius: 5px;
  border: solid 1px grey;
  padding: 5px;
  align-items: center;
  display: flex;
  vertical-align: middle;
}
.sideCounterPrice .increaseButton {
  background-color: rgb(189, 238, 189);
  height: 32px;
  border-radius: 5px;
  border: solid 1px grey;
  padding: 7px;
  align-items: center;
  display: flex;
}

.sideCounterPrice p {
  padding: 0px 9px;

  width: 35px;
  height: 32px;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border: solid 1px grey;
  align-items: center;
  display: flex;
}

.sidesDetailsHeading p {
  font-size: 18px;
}
.sidesDetailsHeading h3 {
  font-size: 22px;
  font-weight: bold;
  height: 45px;
}

.addMeal {
  border: none;
  background-color: #e41e31;
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  margin-right: 15px;
}

.mobileView {
  display: none;
}

.buildTaco {
  display: flex;
}
.buildTacoImage {
  flex: 0 0 30%;
  padding: 10px;
  border-radius: 5px;
}

.buildMenuDeal {
  border-radius: 5px;
  box-shadow: 4px 4px rgb(239, 240, 240);
}
.buildTacoText {
  flex: 0 0 70%;
  padding: 10px;
}

.buildTacoText h3 {
  font-weight: bold;
  font-size: 1rem;
}
.buildTacoText p {
  font-weight: 300;
  font-size: 1rem !important;
}

.chooseEntree {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.chooseEntree img {
  height: 5rem;
  width: 5rem;
  border-radius: 30px;
  box-shadow: 2px 2px 2px 2px rgb(214, 215, 216);
  margin-top: 8px;
  margin-bottom: 8px;
}

.chooseEntreeText p {
  margin-bottom: 0rem;
  font-size: 16px !important;
}
.chooseEntreeText h5 {
  font-size: 16px !important;
}
.AddCartButton {
  border: none;
  background-color: #e41e31;
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  margin-right: 15px;
}
.franchiseAbout {
  background: linear-gradient(45deg, #eee92525, transparent);
}

@media screen and (max-width: 1024px) {
  .buildMealHead h2 {
    font-size: 3rem;
  }
  .buildMealHead p {
    font-size: 1.5rem;
  }
  .entreeText h2 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .mobileView {
    display: block;
  }
  .mealsheading h3 {
    font-size: 0.72rem;
  }

  .desktopVersion {
    display: none;
  }

  .mainMenu {
    width: 100%;
  }

  .sidesInfo {
    width: 100%;
  }

  .buildMeal {
    display: block;
  }

  .menuButtonSec {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}
