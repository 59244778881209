.closeCart svg {
  font-size: 4.4rem;
  color: #e31e31;
}

.cartButton {
  padding: 20px;
  text-align: center;
}

.orderButton {
  color: hsl(0, 0%, 100%);
  padding: 8px;
  text-align: center;
  /* border: 1px solid #e31e31; */
  background-color: #00a750;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  margin-top: 20px;
  border-radius: 12px;
}

.orderButton:hover {
  background-color: #e31e31;
  color: #ffffff;
}

.aboutCart {
  color: #000000;
  text-align: center;
  padding: 0px 40px;
}

.selectedMeal {
  background-color: #f8f8f8;
  padding: 20px;
}

.mealsHead {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  color: #000000;
  font-weight: 500;
}
.mealsList {
  color: #000000;
  display: flex;
  justify-content: space-between;
}
.customizeButtom {
  border: none;
  padding: 5px 10px;
  margin-right: 20px;
  border-bottom: 1px solid #e31e31;
  color: #e31e31;
  background-color: transparent;
}
.addMenuButton {
  width: 100%;
  margin-top: 15px;
  padding: 5px 10px;
  border: 2px solid #e31e31;
  color: #e31e31;
  background-color: transparent;
}

.addMenuButton:hover {
  background-color: #e31e31;
  color: #ffffff;
}

.napkinsCheck {
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}
.taxDetails {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
  color: #000000;
  font-weight: 400;
  font-size: 18px;
}
.finalTotal {
  display: flex;
  justify-content: space-between;
  color: #e31e31;
  font-weight: 700;
  font-size: 22px;
  border-top: 1px solid #dee2e6;
  padding: 10px 0px 0px;
}

.checkoutButton {
  background-color: #e31e31;
  width: 100%;
  padding: 10px;
  border: 1px solid #e31e31;
  color: #ffffff;
}

.checkoutButton:hover {
  background-color: #ffffff;
  color: #e31e31;
}

.carryout {
  background-color: #00a750;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 8px;
}

.delText {
  color: #e31e31;
  padding: 10px 0px 0px;
}

.carryout :hover {
  background-color: #e31e31;
  color: #ffffff;
}
.selectSect {
  font-size: 24px;
  font-weight: 600;
  color: #e31e31;
  padding: 10px 0px;
}

.chekoutIcon {
  padding: 0px 10px;
  color: #ffffff !important;
}

.popUpSelect {
  text-align: center;
}
