.checkout {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  width: 66.66%;
  margin: 0 auto;
  padding: 18px 15px 0px 15px;
  margin-top: 20px;
}

.pickupLocation {
  padding: 10px 20px;
  border-top: 1px solid #ccc;
  text-align: center;
}

.addLocation {
  font-size: 1.25rem;
  text-align: center;
  font-weight: 500;
}

.mealInfo {
  display: flex;
  justify-content: space-between;
  padding: 6px 18px;
  text-transform: capitalize;
}
.mealInfo span {
  color: #198754;
}

.mealList {
  padding: 0px 20px;
}

.totalPrice {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px 14px 16px;
  border-top: 1px solid #ccc;
}

.payPrice {
  color: red;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-top: 1px solid #ccc;
  /* border-bottom: 1px solid #ccc; */
  margin: 12px 0px;
}

.mealname {
  color: #e31e31;
}

.mealPrice {
  color: #000000;
}

.formcontrol {
  width: 51%;
}

.selectTime {
  padding: 10px 15px;
  margin: 5px;
  background-color: #e31e31;
  color: #ffffff;
  border: 2px solid #e31e31;
  border-radius: 12px;
}

.selectTime:hover {
  background-color: #ffffff;
  color: #e31e31;
}

.orderButton {
  /* background-color: #e31e31; */
  width: 100%;
  padding: 10px;
  border: 1px solid #e31e31;
  color: #e3ac00 !important;
  text-align: center;
}

.orderButton:hover {
  background-color: #ffffff;
  color: #e31e31;
}

@media screen and (max-width: 768px) {
  .checkout {
    width: 100%;
    padding: 0px;
  }

  .formcontrol {
    width: 95%;
  }
}
