/* .storeLocation {
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
}

.buildMeal {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.AboutLocation {
  flex: 0 0 32%;
  padding-bottom: 20px;
}
.sidesMain {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sidesMain a {
  flex: 0 0 49%;
}

.sidesSection {
  font-size: 1.5rem;
}

@media screen and (max-width: 767px) {
  .buildMeal {
    display: block;
  }
} */

a {
  color: #898988 !important;
  text-decoration: none !important;
}

li {
  list-style: none;
}

::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}

body {
  font-size: 20px;
  font-weight: normal;
  font-family: "sans-serif" !important;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

label {
  color: #898988;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #e41e31;
}

p {
  color: #000000;
}

.nav_bg {
  background-color: #ffffff;
  /* border-bottom: 1px solid #ccc; */
}

.navbar-brand img {
  width: 224px;
  height: auto;
  border-radius: 12px;
}

.navbar a:hover {
  color: #bf1424 !important;
  text-decoration: underline #bf1424;
}

.navbar-toggler {
  border: none !important;
}

.navbar {
  width: 100%;
  box-shadow: none !important;
}

.navbar-brand {
  width: 25%;
}

/* header css */
.delivery {
  text-align: center;
  padding: 15px 0px;
}
.orderText h2 {
  font-size: 2.5rem;
  font-weight: 800;
  color: #e41e31;
}
.deliveryButton {
  padding: 10px;
}
.deliveryButton span {
  color: #e41e31;
  padding: 10px;
  font-size: 1.5rem;
  font-weight: 800;
}
.orderNow {
  border-color: #e41e31;
  color: #fff;
  box-shadow: 0 0 40px 40px #33a948 inset, 0 0 0 0 #33a948;
  transition: all 150ms ease-in-out;
  padding: 10px 15px;
  border: none;
  border-radius: 12px;
  font-size: 20px;
}

/* cssssssss */

header .item {
  height: 100vh;
  position: relative;
}
header .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
header .item .cover {
  padding: 75px 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
}
header .item .cover .header-content {
  position: relative;
  padding: 56px;
  overflow: hidden;
}
header .item .cover .header-content .line {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  border: 9px solid #fff;
  -webkit-clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
  clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
}
header .item .cover .header-content h2 {
  font-weight: 300;
  font-size: 35px;
  color: #fff;
}
header .item .cover .header-content h1 {
  font-size: 56px;
  font-weight: 600;
  margin: 5px 0 20px;
  word-spacing: 3px;
  color: #fff;
}
header .item .cover .header-content h4 {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  color: #fff;
}
header .owl-item.active h1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  animation-delay: 0.3s;
}
header .owl-item.active h2 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  animation-delay: 0.3s;
}
header .owl-item.active h4 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 0.3s;
}
header .owl-item.active .line {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
  animation-delay: 0.3s;
}
header .owl-nav .owl-prev {
  position: absolute;
  left: 15px;
  top: 43%;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  background: rgba(0, 0, 0, 0.5) !important;
  width: 40px;
  cursor: pointer;
  height: 40px;
  position: absolute;
  display: block;
  z-index: 1000;
  border-radius: 0;
}
header .owl-nav .owl-prev span {
  font-size: 1.6875rem;
  color: #fff;
}
header .owl-nav .owl-prev:focus {
  outline: 0;
}
header .owl-nav .owl-prev:hover {
  background: #000 !important;
}
header .owl-nav .owl-next {
  position: absolute;
  right: 15px;
  top: 43%;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  background: rgba(0, 0, 0, 0.5) !important;
  width: 40px;
  cursor: pointer;
  height: 40px;
  position: absolute;
  display: block;
  z-index: 1000;
  border-radius: 0;
}
header .owl-nav .owl-next span {
  font-size: 1.6875rem;
  color: #fff;
}
header .owl-nav .owl-next:focus {
  outline: 0;
}
header .owl-nav .owl-next:hover {
  background: #000 !important;
}
header:hover .owl-prev {
  left: 0px;
  opacity: 1;
}
header:hover .owl-next {
  right: 0px;
  opacity: 1;
}

/* dhasfkdjsffffffffffffk */
.chooseLocation {
  text-align: center;
}
.chooseLocation h2 {
  font-size: 2.5rem;
  font-weight: 600;
}
.franchise {
  display: flex;
  width: 90%;
  margin: 0 auto;    justify-content: center;
}
.franchiseLeft h2 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #e41e31;
}
.franchiseLeft p {
  font-size: 1.3rem;
  font-weight: 400;
  min-height: 65px;
  color: #666a62;
  margin: 0;
  /* color: #e41e31; */
  text-align: center;
}
.franchiseText {
  display: flex;
}
.address {
  color: #18a249 !important;
  font-size: 1.3rem !important;
  font-weight: 900 !important;
}
/* .franchiseAbout { */
/* display: flex; */
/* justify-content: space-between; */
/* } */
.franchiseLeft img {
  height: auto;
  width: 100%;
  border-radius: 12px;
  background-color: #33a948;
}
.franchiseLeft {
  padding: 20px 10px;
  width: 25%;
  box-shadow: 3px 3px 23px #939393;
  margin: 15px;
  border-radius: 12px;
}

.orderButtonLocation button {
  padding: 10px;
  border: none;
  background-color: #e41e31;
  color: #ffffff;
  font-size: 20px;
  border-radius: 12px;
  width: 100%;
}
.franchiseLeft h2 {
  padding: 15px 0px;
  text-align: center;
}

.container-fluid.footer {
  background-color: #fc740bcc;
  padding: 26px 16px;
  color: #fff;
}

.footer ul {
  list-style: none;
}

.footer li {
  padding: 2px;
  font-size: 13px;
}

.footer h3 {
  color: #eb0b0b;
  font-size: 25px;
  font-weight: 700;
}

.footer i.fa-brands {
  color: #fff;
  font-size: 25px;
}

p.reserved {
  text-align: center;
  width: 100%;
}

.footer p {
  padding-top: 18px;
  font-size: 20px;
  color: #ffffff;
}
.icon li {
  font-size: 20px;
}
.socail-icon li a svg {
  width: 50%;
}
ul.socail-icon {
  display: flex;
  justify-content: space-around;
}
.temparayClosed button {
  padding: 10px;
  border: none;
  background-color: #666a62;
  color: #ffffff;
  font-size: 20px;
  border-radius: 12px;
  width: 100%;
}
.franchiseAbout {
  background: linear-gradient(45deg, #eee92525, transparent);
}
@media screen and (max-width: 767px) {
  .franchise {
    display: block;
  }

  .footer_section.d-flex {
    display: block !important;
  }

  .franchiseLeft {
    width: 100%;
    margin: 0;
  }
}
@media screen and (max-width: 1024px) {
  .franchiseLeft img {
    height: auto;
    width: 100%;
    border-radius: 12px;
  }
}
