.LocationComponents {
  position: relative;
  margin-bottom: 200px;
}

.locationMenuBanner {
  position: absolute;
  bottom: -158px;
  left: 0;
  right: 0;
}

.item .sliderImage {
  border-radius: 50%;
  object-fit: cover;
  height: 10vw;
  width: 10vw !important;
  box-shadow: 5px 5px 6px rgb(203, 204, 204);
  margin-bottom: 8px;
}

/* TESTIMONIAL SLIDER */

.single-review {
  -webkit-box-shadow: 0 0.313rem 0.875rem #ccc;
  box-shadow: 0 0.313rem 0.875rem var(--secondary-l-color);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-review .card-top {
  position: relative;
}

.single-review .quot-icon {
  position: absolute;
  top: 5%;
  right: 5%;
}

.single-review .reviewer {
  position: relative;
}

.single-review .reviewer::after {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #fff;
  bottom: calc(100% - 10px);
  left: 15%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.single-review:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
  box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125);
}

.rounded-pill {
  color: #0066fd !important ;
}

/* galley section */

.gallery {
  font-size: 20px;
  background: url("https://images.pexels.com/photos/172277/pexels-photo-172277.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  background-repeat: repeat;
  color: #000;
  height: 480px;
}

.gallery h2 {
  text-align: center;
  font-size: 3rem;
  color: #ffffff;
}
.gallery p {
  text-align: center;
  font-size: 1.5rem;
  color: #eb2431;
}

.gallery ul {
  position: absolute;
  margin: 0;
  padding: 0;
}

.gallery ul li {
  text-align: center;
  display: inline-block;
  list-style: none;
  position: relative;
  border: solid 12px #fff;
  background: #fff;
  box-shadow: 0 0 15px 0px #555;
  transition: all 1s ease;
  -o-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  top: 50px;
  margin-left: 25px;
}

.gallery ul li:hover {
  top: 0px;
  opacity: 0.5;
}

.gallery ul li:nth-child(1) {
  transform: rotate(10deg);
}

.gallery ul li:nth-child(2) {
  transform: rotate(0deg);
}

.gallery ul li:nth-child(3) {
  transform: rotate(-10deg);
}

.gallery ul li:nth-child(4) {
  transform: rotate(20deg);
}
.gallery ul li:nth-child(5) {
  transform: rotate(30deg);
}

.gallery ul li p {
  margin: -15px 0 0 0;
}

/* faq desing css */

.content {
  width: 82%;
  padding: 20px;
  margin: 0 auto;
  padding: 0 60px 0 0;
}

.centerplease {
  margin: 0 auto;
  max-width: 270px;
  font-size: 40px;
}

.question {
  color: #fff;
  position: relative;
  background: #00a252;
  margin: 0;
  padding: 10px 10px 10px 50px;
  display: block;
  width: 100%;
  cursor: pointer;
  margin-bottom: 15px;
  border-radius: 15px;
}

.answers {
  font-weight: 300;
  background: #f2f2f2;
  padding: 0px 15px;
  margin: 0px 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.7s ease;
  -moz-transition: 0.7s ease;
  -o-transition: 0.7s ease;
  transition: 0.7s ease;
}

.questions:checked ~ .answers {
  height: auto;
  opacity: 1;
  padding: 15px;
}

.plus {
  color: #fff;
  position: absolute;
  margin-left: 10px;
  margin-top: 5px;
  z-index: 5;
  font-size: 2em;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.questions:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.questions {
  display: none;
}

.content h1 {
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
}

/* get in touch */

body {
  color: #fff;
}
.right_conatct_social_icon {
  background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
}
.contact_us {
  /* //background-color: #f1f1f1; */
  padding: 120px 0px;
}

.contact_inner {
  background-color: #fff;
  position: relative;
  box-shadow: 20px 22px 44px #cccc;
  border-radius: 25px;
}
.contact_field {
  padding: 60px 340px 90px 100px;
}
.right_conatct_social_icon {
  height: 100%;
}

.contact_field h3 {
  color: #000;
  font-size: 40px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px;
}
.contact_field p {
  color: #000;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 35px;
}
.contact_field .form-control {
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;
}
.contact_field .form-control:focus {
  box-shadow: none;
  outline: none;
  border-bottom: 2px solid #e41e31;
}
.contact_field .form-control::placeholder {
  font-size: 13px;
  letter-spacing: 1px;
}

.contact_info_sec {
  position: absolute;
  background-color: #2d2d2d;
  right: 1px;
  top: 18%;
  height: 340px;
  width: 340px;
  padding: 40px;
  border-radius: 25px 0 0 25px;
}
.contact_info_sec h4 {
  letter-spacing: 1px;
  padding-bottom: 15px;
}

.info_single {
  margin: 30px 0px;
}
.info_single i {
  margin-right: 15px;
}
.info_single span {
  font-size: 14px;
  letter-spacing: 1px;
}

button.contact_form_submit {
  border: none;
  color: #fff;
  padding: 10px 15px;
  width: 100%;
  margin-top: 25px;
  border-radius: 35px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 2px;

  border: none;
  background-color: #e41e31;
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  margin-right: 15px;
}
.socil_item_inner li {
  list-style: none;
}
.socil_item_inner li svg {
  color: #fff;
  margin: 0px 10px;
  font-size: 24px;
}
.socil_item_inner {
  padding-bottom: 10px;
}

.map_sec {
  padding: 50px 0px;
}
.map_inner h4 {
  color: #000;
  text-align: center;
  font-size: 32px;
}
.map_inner p {
  font-size: 16 px;
  color: #000;
  text-align: center;
}
.map_bind {
  margin-top: 50px;
  border-radius: 30px;
  overflow: hidden;
}

/* about us section */

.sec-title {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}

.sec-title .title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #00aeef;
  font-weight: 500;
  margin-bottom: 15px;
}

.sec-title h2 {
  position: relative;
  display: block;
  font-size: 40px;
  line-height: 1.28em;
  color: #222222;
  font-weight: 600;
  padding-bottom: 18px;
}

.sec-title h2:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  width: 50px;
  height: 3px;
  background-color: #d1d2d6;
}

.sec-title .text {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #848484;
  font-weight: 400;
  margin-top: 35px;
}

.sec-title.light h2 {
  color: #ffffff;
}

.sec-title.text-center h2:before {
  left: 50%;
  margin-left: -25px;
}

.list-style-one {
  position: relative;
}

.list-style-one li {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #222222;
  font-weight: 400;
  padding-left: 35px;
  margin-bottom: 12px;
}

.list-style-one li:before {
  content: "\f058";
  position: absolute;
  left: 0;
  top: 0px;
  display: block;
  font-size: 18px;
  padding: 0px;
  color: #ff2222;
  font-weight: 600;
  -moz-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1.6;
  font-family: "Font Awesome 5 Free";
}

.list-style-one li a:hover {
  color: #44bce2;
}

.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 30px;
  color: #ffffff !important;
  padding: 10px 30px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.02em;
  background-color: #00aeef;
}

.btn-style-one:hover {
  background-color: #0794c9;
  color: #ffffff;
}
.about-section {
  position: relative;
  padding: 120px 0 70px;
}

.about-section .sec-title {
  margin-bottom: 45px;
}

.about-section .content-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section .content-column .inner-column {
  position: relative;
  padding-left: 30px;
}

.about-section .text {
  margin-bottom: 20px;
  font-size: 24x;
  line-height: 26px;
  color: #848484;
  font-weight: 400;
}

.about-section .list-style-one {
  margin-bottom: 45px;
}

.about-section .btn-box {
  position: relative;
}

.about-section .btn-box a {
  padding: 15px 50px;
}

.about-section .image-column {
  position: relative;
}

.about-section .image-column .text-layer {
  position: absolute;
  right: -110px;
  top: 50%;
  font-size: 325px;
  line-height: 1em;
  color: #ffffff;
  margin-top: -175px;
  font-weight: 500;
}

.about-section .image-column .inner-column {
  position: relative;
  padding-left: 80px;
  padding-bottom: 0px;
}
.about-section .image-column .inner-column .author-desc {
  position: absolute;
  bottom: 16px;
  z-index: 1;
  background: orange;
  padding: 10px 15px;
  left: 96px;
  width: calc(100% - 152px);
  border-radius: 50px;
}
.about-section .image-column .inner-column .author-desc h2 {
  font-size: 21px;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  margin: 0;
}
.about-section .image-column .inner-column .author-desc span {
  font-size: 16px;
  letter-spacing: 6px;
  text-align: center;
  color: #fff;
  display: block;
  font-weight: 400;
}
.about-section .image-column .inner-column:before {
  content: "";
  position: absolute;
  width: calc(50% + 80px);
  height: calc(100% + 160px);
  top: -80px;
  left: -3px;
  background: transparent;
  z-index: 0;
  border: 44px solid #00aeef;
}

.about-section .image-column .image-1 {
  position: relative;
}
.about-section .image-column .image-2 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.about-section .image-column .image-2 img,
.about-section .image-column .image-1 img {
  box-shadow: 0 30px 50px rgba(8, 13, 62, 0.15);
  border-radius: 46px;
}

.about-section .image-column .video-link {
  position: absolute;
  left: 70px;
  top: 170px;
}

.about-section .image-column .video-link .link {
  position: relative;
  display: block;
  font-size: 22px;
  color: #191e34;
  font-weight: 400;
  text-align: center;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 30px 50px rgba(8, 13, 62, 0.15);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.about-section .image-column .video-link .link:hover {
  background-color: #191e34;
  color: #ffffff;
}

@media screen and (max-width: 1024px) {
  .gallery {
    height: 710px;
  }

  .contact_field {
    padding: 20px;
  }
  .contact_info_sec {
    position: relative;

    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .gallery {
    height: 1480px;
  }

  .gallery ul {
    text-align: center;
  }

  .content {
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    /* padding: 0 0px 0 0; */
  }

  .contact_us {
    padding: 0px;
  }

  .contact_field {
    padding: 20px;
  }

  .contact_info_sec {
    position: relative;
  }
}
