* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: #898988 !important;
  text-decoration: none !important;
}

li {
  list-style: none;
}

::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}

body {
  font-size: 20px;
  font-weight: normal;
  font-family: "Playfair Display" !important;
}

img {
  max-width: 100%;
  height: auto;
}
label {
  color: #898988;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #e41e31;
}

p {
  color: #898989;
}

/* .menuheading {
  font-size: 2.5rem;
  color: #000000;
} */

.speciality {
  color: #e31e31;
  text-align: center;
  font-size: 24px;
  padding: 5px;
  font-weight: 700;
}

.joinus {
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 25px 10px;
}

.joinus p {
  padding: 1rem 0.75rem 0rem;
  font-weight: 600;
}

.orderButton {
  color: hsl(0, 0%, 100%);
  border: 1px solid #e31e31;
  border-radius: 4px;
  background-color: #ffffff;
  color: #e31e31;
  width: 200px;
  padding: 0.375rem 0.75rem;
  font-weight: 600;
}

.orderButton:hover {
  background-color: #e31e31;
  color: #ffffff;
}

.storySection img {
  margin-bottom: 7rem;
}

.botIcon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
  position: fixed;
  bottom: 45px;
  z-index: 9999;
  right: 40px;
  cursor: pointer;
}
.app-chatbot-button {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #c7d3d4ff;
  border: none;
  position: fixed;
  bottom: 45px;
  z-index: 9999;
  right: 40px;
  cursor: pointer;
}
.chatbotMenu {
  color: red !important;
}

.rsc {
  position: fixed;
  bottom: 100px;
  z-index: 9999;
  right: 40px;
}

.GgOGn {
  width: 100%;
}

.glfuN {
  background-color: #e41e31 !important;
  max-width: 100% !important;
}
.hQsUiY {
  background-color: #e41e31 !important;
  max-width: 100% !important;
}
.cRmLCo {
  background-color: #e41e31 !important;
  max-width: 100% !important;
}
.ipSaXD {
  background-color: #e41e31 !important;
  max-width: 100% !important;
}
.glfuN h2 {
  color: #ffffff !important;
}

@media screen and (max-width: 1024px) {
  .joinus {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .joinus {
    display: block;
    text-align: center;
    width: 100%;
  }

  .joinus p {
    padding: 5px;
    margin-bottom: 0.1rem !important;
  }

  .fBGGBn {
    height: calc(520px - 200px);
    overflow-y: scroll;
    margin-top: 2px;
    padding-top: 6px;
  }
}
