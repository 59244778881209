.findUs {
  text-align: center;
  width: 66%;
  margin: 0 auto;
  padding: 50px 0px 20px 0px;
}

.findUs h2 {
  font-size: 3.5rem;
}

.formButton {
  cursor: pointer;
  border-radius: 3rem;
  background-color: #e41e31;
  padding: 0.5rem 3rem;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 1.2rem;
}

.findUs ul {
  padding: 20px 0px;
}

.findUs ul li:nth-child(1) {
  color: #464646;
  font-size: 2rem;
}

.findUs ul li {
  color: #898989;
}

.contactus {
  display: flex;
  justify-content: space-between;
}

.findUsSec {
  width: 80%;
  margin: 0 auto;
}

.innerContact {
  flex: 0 0 33%;
}

.innerContact svg {
  font-size: 3.5rem;
  padding-bottom: 10px;
  color: #ef3a46;
}
.innerContact h4 {
  color: #464646;
}

@media screen and (max-width: 720px) {
  .findUs {
    width: 100%;
  }

  .contactus {
    display: block;
  }

  .findUs h2 {
    font-size: 2.5rem;
  }
}
