.footer {
  /* position: relative; */
  /* margin: 0px; */
  background-color: #e31e31;

  /* padding: 1.6rem 0.5rem; */
  /* margin-top: 30px; */
  /* background-size: cover; */
  /* font-size: 14px; */
  /* position: relative; */
}

/* .footer:before {
    content: "";
    background: rgb(255 246 246 / 40%);
    position: absolute;
    inset: 0;
} */

.footercontent {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: left;
  padding-top: 50px;
  /* position: relative !important; */
}

.sub {
  display: flex;
  justify-content: space-evenly;
}

.sub ul {
  padding-left: 0px;
  flex: 0 0 25%;
  color: #ffffff;
}

.sub ul li {
  margin: 10px 10px;
  font-size: 16px;
}

.sub ul li:nth-child(1) {
  font-size: 1rem;
  font-weight: 700;
}

.icondes {
  background-color: #ffffff;
  color: #e31e31;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  width: 40px;
  position: relative;
  display: inline-block;
}

.icondes:hover {
  background-color: #e31e31;
  color: #ffffff;
}

.footerhead {
  font-size: 2em;
  text-align: center;
}

.footertext {
  text-align: center;
}

.icondes svg {
  position: absolute;
  top: 11px;
  left: 11px;
  z-index: 454545;
  text-align: center;
}

.copywrite {
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  border-top: 1px solid #ffffff;
  padding-top: 15px;
}

@media screen and (max-width: 720px) {
  .sub {
    flex-direction: column;
  }

  .footerhead {
    font-size: 2em;
    text-align: left;
  }

  .footertext {
    text-align: left;
  }
}
