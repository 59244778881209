.brand-name {
  color: rgb(37, 113, 180);
}

.nav_bg {
  background-color: #ffffff;
  /* border-bottom: 1px solid #ccc; */
}

#header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.navbar-brand img {
  width: 200px;
  height: auto;
}

.navbar a:hover {
  color: #bf1424 !important;
  text-decoration: underline #bf1424;
}

.navbar-toggler {
  border: none !important;
}

.navbar {
  width: 100%;
  box-shadow: none !important;
}

.navbar-brand {
  width: 25%;
}

.navbar-collapse {
  width: 41%;
}

.collapse ul li {
  padding-right: 10px;
  font-size: 22px;
}

.headingIcon ul li {
  padding-right: 32px;
  color: #898989 !important;
  font-size: 20px;
  cursor: pointer;
}

.nav-link {
  color: #898989 !important;
  font-weight: 400 !important;
  font-size: 20px;
}

.dashboard {
  display: flex !important;
  padding-top: 8px;
}
.socialLinks {
  font-weight: 700;
  color: #ffffff;
  height: 40px;
  width: 40px;
  background-color: #898989;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  margin-right: 17px;
  margin-bottom: 5px;
}

.socialLinks svg {
  left: 0;
  bottom: 8px;
  position: absolute;
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
}

@media screen and (max-width: 1024px) {
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
  }

  .headingIcon ul li {
    padding-right: 5px;
  }
}

@media screen and (max-width: 767px) {
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
  }

  .headingIcon ul li {
    padding-right: 10px;
  }

  .navbar-brand {
    width: 50%;
  }

  .headingIcon ul li {
    font-size: 16px;
  }
}
