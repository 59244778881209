.ourStory {
  display: flex;
  align-items: center;
}

.storySection {
  padding: 10px 10px;
  flex: 0 0 33%;
}
.storyText h2 {
  font-size: 2.5rem;
}

.storyBottom {
  text-align: center;
  background-color: #fcd0d4;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.storyBottomcontnet {
  width: 50%;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .ourStory {
    display: block;
  }
}

@media screen and (max-width: 720px) {
  .ourStory {
    display: block;
  }
}
