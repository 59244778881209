.menutable {
    display: flex;
    align-items: center;
  }
  
  .menutableSection {
    padding: 10px 10px;
    flex: 0 0 33%;
  }
  .menutableText h2 {
    font-size: 2.5rem;
  }
  
  .menutableBottom {
    text-align: center;
    background-color: #fcd0d4;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  .menutableBottomcontnet {
    width: 50%;
    margin: 0 auto;
  }
  
  @media screen and (max-width: 1024px) {
    .menutable {
      display: block;
    }
  }
  
  @media screen and (max-width: 720px) {
    .menutable {
      display: block;
    }
  }
  