.order-fluid {
    background: #e1e1e1;
}

.emp-profile {
    /* margin-top: 3%; */
    padding: 3%;
    /* margin-bottom: 3%; */
    border-radius: 0.5rem;
    background: #fff;
}

.col-ft {
    color: #e31e31 !important;
    font-size: 18px !important;
    font-weight: 700 !important;
}

/* .me-2{
    
} */




.profile-img {
    text-align: center;
}

.profile-img img {
    width: 70%;
    height: 100%;
}

.profile-img .file {
    position: relative;
    overflow: hidden;
    margin-top: -20%;
    width: 70%;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: #212529b8;
}

.profile-img .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
}

.profile-head h5 {
    color: #333;
}

.profile-head h6 {
    color: #0062cc;
}

.profile-edit-btn {
    border: none;
    border-radius: 1.5rem;
    width: 70%;
    padding: 2%;
    font-weight: 600;
    color: #6c757d;
    cursor: pointer;
}

.proile-rating {
    font-size: 12px;
    color: #818182;
    margin-top: 5%;
}

.proile-rating span {
    color: #495057;
    font-size: 15px;
    font-weight: 600;
}

.profile-head .nav-tabs {
    margin-bottom: 5%;
}

.profile-head .nav-tabs .nav-link {
    font-weight: 600;
    border: none;
}

.profile-head .nav-tabs .nav-link.active {
    border: none;
    border-bottom: 2px solid #0062cc;
}

.nav-tabs .nav-link {
    padding: 17px 0px 16px 30px
}

.profile-work {
    padding: 14%;
    margin-top: -15%;
}

.profile-work p {
    font-size: 12px;
    color: #818182;
    font-weight: 600;
    margin-top: 10%;
}

.profile-work a {
    text-decoration: none;
    color: #495057;
    font-weight: 600;
    font-size: 14px;
}

.profile-work ul {
    list-style: none;
}

.profile-tab label {
    font-weight: 600;
}

.profile-tab p {
    font-weight: 600;
    color: #0062cc;
}

.col-md-6 {
    padding-left: 50px
}

.col-md-3 {
    padding-left: 50px
}

.nav-tabs {
    border: none;
}



@media screen and (max-width:767px) {

    .order-details {
        display: flex;
        flex-wrap: nowrap;
    }

    .overFlow-y {
        overflow-y: auto;
    }

    .col-md-3 {
        padding-left: 50px;
        width: 50%;
    }

    .col-md-11 {
        padding-left: 1px;
        padding-right: 1px;
    }
}